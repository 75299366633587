import React from 'react';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = ({
  editScreen,
  GridFlag,
  TenantFilterCell,
  BooleanValuesFilterCell,
  MultiViewCalenderFilterCell,
  dateRangeFilter,
  setDateRangeFilter,
  GridDateCell,
}) => {
  const dataColumns = [
    {
      field: 'row_no',
      title: 'Row No',
      editable: false,
      show: false,
      width: '250px',
      width: '250px',
      minResizableWidth: 150,
      filterable: true,
      excludeFromFilter: true,
    },
    {
      field: 'uc_ossi_test_case_id',
      title: 'Test Case Id',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className='edit-link'
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.uc_ossi_test_case_id}
        </td>
      ),
    },
    {
      field: 'tenant_name',
      title: 'Tenant',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 150,
      filterCell: (props) =>
        TenantFilterCell({
          ...props,
          filterType: 'dropdown',
        }),
    },
    {
      field: 'uc_ossi_run_id',
      title: 'Run Id',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_prod_id',
      title: 'Prod Id',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },

    {
      field: 'uc_ossi_app_test_id',
      title: 'Test Id',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_run_num',
      title: 'Run Num',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_actual_result',
      title: 'Result',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'start_dt',
      title: 'Start Date',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      filter: 'date',
      type: 'dateRange',
      filterCell: (props) =>
        MultiViewCalenderFilterCell({
          ...props,
          dateRangefilter: dateRangeFilter,
          setDateRangeFilter,
          field: 'start_dt',
        }),
      isRequired: true,
      cell: GridDateCell,
    },
    {
      field: 'end_dt',
      title: 'End Date',
      editable: false,
      show: true,
      width: '300px',
      minResizableWidth: 50,
      filter: 'date',
      type: 'dateRange',
      filterCell: (props) =>
        MultiViewCalenderFilterCell({
          ...props,
          dateRangefilter: dateRangeFilter,
          setDateRangeFilter,
          field: 'end_dt',
        }),
      isRequired: true,
      cell: GridDateCell,
    },
    {
      field: 'uc_ossi_exec_ms',
      title: 'Exec Milliseconds',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_test_case_status',
      title: 'Status',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_test_case_message',
      title: 'Message',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_test_case_cmnt',
      title: 'Comment',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_test_case_valdt_status',
      title: 'Valdt Status',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_test_case_valdt_message',
      title: 'Valdt Message',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
    },
    {
      field: 'uc_ossi_test_case_valdt_cmnt',
      title: 'Valdt Comment',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_test_grp_id',
      title: 'Platform',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_cmd_result',
      title: 'Command Result',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_pre_exec_cmd_result',
      title: 'Pre Execution Command Result',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_post_exec_cmd_result',
      title: 'Post Execution Command Result',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: 'uc_ossi_cmd_flg',
      title: 'Command Flag?',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
      cell: GridFlag,
      filterCell: BooleanValuesFilterCell,
    },
    {
      field: 'uc_ossi_pre_exec_cmd_flg',
      title: 'Pre Execution Command Flag?',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
      cell: GridFlag,
      filterCell: BooleanValuesFilterCell,
    },
    {
      field: 'uc_ossi_post_exec_cmd_flg',
      title: 'Post Execution Command Flag?',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: true,
      cell: GridFlag,
      filterCell: BooleanValuesFilterCell,
    },
  ];

  return dataColumns;
};
export default DataColumns;
